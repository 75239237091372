import React from 'react';
import { Layout, Seo } from 'components';

// import page style
import './index.scss';

const IndexPage = () => {
  /**
   * Remove all the HTML and CSS contents from this page to use your own.
   * All the contents in this page is just for the demonstration purpose.
   * You may use React Flexible Grid or any other grid of your choice
   * The following styles should be moved into the page specific scss file.
   */

  const columnStyle = {
    color: 'white',
    flex: 1,
    marginRight: 15,
  };
  const photoStyle = {
    height: 100,
    backgroundColor: 'gray',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };
  return (
    <Layout indication="patient" className="patient-home">
      <Seo pageTitle="Patient Home" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actuall content goes here */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 500,
              background: 'gray',
              color: 'white',
            }}
          >
            TBD Hero Image
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              posuere ultrices sagittis. Integer accumsan enim orci, ac tempus
              mi maximus id. Sed faucibus molestie nunc at fermentum. In vitae
              justo eu sem mollis vulputate nec fermentum lorem. Aenean in
              mollis mi. Sed eleifend laoreet ultricies. Nullam a sollicitudin
              justo. Proin diam sapien, ultrices a dui id, efficitur luctus
              neque. Suspendisse arcu augue, dignissim a magna non, porttitor
              ullamcorper eros. Donec sed purus auctor est dignissim faucibus et
              vitae tortor. Duis maximus massa tellus, eu varius velit tristique
              vel. Nam pulvinar ac magna ac iaculis. Morbi tempus eleifend
              purus, ac bibendum velit efficitur et. Aliquam lobortis orci quis
              turpis consequat fermentum. Proin a mauris dapibus, tincidunt
              nulla quis, convallis elit. Etiam vitae neque ex.
            </p>
            <p>
              Etiam posuere felis ac aliquam tempus. Morbi sollicitudin dui dui,
              eget commodo tortor pulvinar sit amet. Phasellus venenatis pretium
              imperdiet. Pellentesque ex odio, rutrum vel dolor eu, vulputate
              iaculis lectus. Duis viverra turpis a risus interdum maximus.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Aliquam gravida sem id nisi
              dignissim dignissim. Vestibulum placerat pellentesque leo ac
              maximus. Vestibulum consectetur mauris eget justo pharetra
              scelerisque non in magna. Sed dui tellus, tristique vel quam sed,
              tempus auctor orci. Proin ultrices ipsum purus. Maecenas accumsan
              tellus nec elementum consectetur. Proin nec magna volutpat,
              pellentesque tortor quis, pulvinar ipsum. Suspendisse maximus
              lorem ac mi consequat, ac condimentum magna gravida.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={columnStyle}>
              <div style={photoStyle}>Photo</div>
              <div>
                Sed viverra tincidunt lectus in blandit. Suspendisse potenti.
                Suspendisse quis tortor ante. Morbi at arcu vitae lectus
                venenatis dapibus vel non arcu. Sed non lorem sit amet augue
                accumsan finibus.
              </div>
            </div>
            <div style={columnStyle}>
              <div style={photoStyle}>Photo</div>
              <div>
                Nullam laoreet maximus massa, at aliquet urna lacinia volutpat.{' '}
              </div>
            </div>
            <div style={{ ...columnStyle, marginRight: 0 }}>
              <div style={photoStyle}>Photo</div>
              <div>
                Sed luctus sapien id metus vestibulum, vel tristique tortor
                ultricies. Praesent scelerisque vitae mi sollicitudin commodo.
                Nam tempus a magna sed placerat.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
